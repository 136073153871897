<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-card>
    <v-card-title class="align-baseline">
      Orders
      <v-switch
          v-model="confirmed"
          class="pa-3"
          label="Confirmed"
      ></v-switch>
      <v-switch
          v-model="pending"
          class="pa-3"
          label="Pending"
      ></v-switch>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-subtitle v-if="selected.length">
      <v-row>
        <v-col>
          <v-toolbar
              dense
              dark
              rounded
              outlined
              max-width="200"
          >
            <v-btn icon small @click="bulkAccept">
              <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-btn icon small @click="bulkDelete">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-data-table
        dense
        :headers="headers"
        :items="orders"
        :options.sync="options"
        :server-items-length="totalPayments"
        :footer-props="footerProps"
        :items-per-page="50"
        :loading="loading"
        item-key="id"
        :search="search"
        class="elevation-1"
        show-select
        v-model="selected"
        @update:options="navigate"
    >
      <template v-slot:top>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.updated_at | calendar }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            color="green"
            @click="editItem(item)"
        >
          mdi-checkbox-marked-outline
        </v-icon>
        <v-icon
            small
            @click="deleteItem(item)"
            color="red"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import axios from 'axios'
import Swal from "sweetalert2"
import moment from "moment";

export default {
  data: () => ({
    orders: [],
    selected: [],
    footerProps: {
      itemsPerPageOptions: [20, 50, 100, 500]
    },
    search: '',
    page: 1,
    pageCount: 0,
    totalPayments: 0,
    options: {},
    loading: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {text: 'Name', value: 'user.name'},
      {text: 'Phone', value: 'phone'},
      {text: 'Book', value: 'book.title'},
      {text: 'Quantity', value: 'quantity'},
      {text: 'Amount', value: 'total'},
      {text: 'Tran. ID', value: 'transaction_number'},
      {text: 'Status', value: 'status'},
      {text: 'Time', value: 'created_at'},
      {text: 'Address', value: 'address'},
      {text: 'Action', value: 'actions', sortable: false}
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    selectedItemForDelete: '',
    free: false,
    confirmed: false,
    pending: false,
    filter: '',
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    selectedIds() {
      return this.selected.map(a => a.id)
    },
    filterTerm() {
      if (this.free) {
        return 'filter=free'
      } else if (this.confirmed) {
        return 'filter=confirmed'
      } else if (this.pending) {
        return 'filter=pending'
      } else {
        return 'filter=all'
      }
    }
  },

  watch: {
    filterTerm() {
      this.initialize()
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    search() {
      if (this.search.length) {
        this.searchPayment()
      } else {
        this.initialize()
      }
    },
    free() {
      if (this.free) {
        this.confirmed = false
        this.pending = false
      }
    },
    confirmed() {
      if (this.confirmed) {
        this.free = false
        this.pending = false
      }
    },
    pending() {
      if (this.pending) {
        this.confirmed = false
        this.free = false
      }
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    bulkAccept() {
      Swal.fire({
        title: 'Confirmation',
        text: "Do you want to accept these orders?",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          const url = 'admin/book-orders/action/bulk'
          axios.post(url, {ids: this.selectedIds, type: 'bulk_accept'}).then(() => {
            Swal.fire(
                'Success!',
                'success'
            )
            this.selected = []
            this.initialize()
          })
        }
      })
    },
    bulkDelete() {
      Swal.fire({
        title: 'Confirmation',
        icon: 'warning',
        text: "Do you want to delete these orders?",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          const url = 'admin/book-orders/action/bulk'
          axios.post(url, {ids: this.selectedIds, type: 'bulk_delete'}).then(() => {
            Swal.fire(
                'Deleted!',
                'success'
            )
            this.selected = []
            this.initialize()
          })
        }
      })
    },
    navigate(event) {
      this.initialize(event.page)
    },
    initialize(page = 1) {
      this.loading = true
      let link = 'admin/book-orders?' + this.filterTerm + '&page=' + page
      axios.get(link).then((response) => {
        this.orders = response.data.data
        this.totalPayments = response.data.total
        this.loading = false
      })
    },
    searchPayment(page = 1) {
      this.loading = true
      let link = 'admin/book-orders?filter=search&page=' + page + '&query=' + this.search
      axios.get(link).then((response) => {
        this.orders = response.data.data
        this.totalPayments = response.data.total
        this.loading = false
      })
    },
    editItem(item) {
      const url = 'admin/book-orders/' + item.id;
      let data = {
        status: 'confirmed'
      };
      axios.put(url, data).then(() => {
        this.initialize();
      })
    },
    deleteItem(item) {
      this.editedIndex = this.orders.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.selectedItemForDelete = item;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      const url = 'admin/book-orders/' + this.selectedItemForDelete.id;
      axios.delete(url).then(() => {
        this.orders.splice(this.editedIndex, 1);
        this.closeDelete();
      }).catch((error) => {
        console.log(error)
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.orders[this.editedIndex], this.editedItem)
      } else {
        this.orders.push(this.editedItem)
      }
      this.close()
    },
  },
  filters: {
    calendar(value) {
      return moment(value).calendar()
    }
  }
}
</script>